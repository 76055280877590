// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:287f5761-dff7-4277-b41a-a1d3fe22023b",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_B6nujRCSI",
    "aws_user_pools_web_client_id": "5516lvlsmisgjmnejhtn68iv6l",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "api",
            "endpoint": "https://jbvjuszn3l.execute-api.us-west-2.amazonaws.com/test",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
