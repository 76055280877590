import React, {Component}from 'react';
import logo from './AWS_Logo.svg';
import './App.css';
import {withAuthenticator} from 'aws-amplify-react'
import Amplify, {Auth}  from 'aws-amplify';
import aws_exports from './aws-exports';
Amplify.configure(aws_exports);

class App extends Component{
  render() {
    return(
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <a
          className="App-link"
          href="https://aws-amplify.github.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          
          
          React + AWS Amplify Rocks!
        </a>
      </header>
    </div>
    );
    }
}

export default withAuthenticator(App, true);
